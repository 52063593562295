<template>
  <div class="crianca_adolescente_background">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="d-flex align-self-center justify-center align-center">
          <img
            width="50%"
            src="../../assets/conteudos_crianca_adolescente_title.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-md-center align-center pt-5 pb-5 ovx">
        <v-col class="about__page_nav-link">
          <router-link
            :to="{ name: 'conteudos_tematicos_crianca_adolescente' }"
          >
            Criança e adolescente
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link
            :to="{ name: 'conteudos_tematicos_crianca_adolescente_ser' }"
          >
            Ser criança e adolescente
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link
            :to="{ name: 'conteudos_tematicos_crianca_adolescente_luto' }"
          >
            Superação do luto
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link
            :to="{
              name:
                'conteudos_tematicos_crianca_adolescente_amadurecimento_precoce',
            }"
          >
            Amadurecimento precoce
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link
            :to="{ name: 'conteudos_tematicos_crianca_adolescente_traumas' }"
          >
            Traumas
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link
            :to="{ name: 'conteudos_tematicos_crianca_adolescente_educacao' }"
          >
            Educação
          </router-link>
        </v-col>
      </v-row>
    </section>

    <transition>
      <router-view></router-view>
    </transition>
    <ScrollTop />
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
import ScrollTop from "@/components/ScrollTop";

export default {
  components: { Breadcrumb, ScrollTop },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Crianças e Adolescentes",
          href: "conteudos_tematicos_crianca_adolescente",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }
  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px rgb(224, 105, 25, 0.6);
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: rgb(224, 105, 25, 0.8);
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}
.crianca_adolescente_background {
  background-image: url("../../assets/conteudos_crianca_adolescente_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}

a {
  color: #1b211b;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}

.router-link {
  color: #1b211b;
}
</style>
